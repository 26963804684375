var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isBeamerAvailable
    ? _c(
        "div",
        [
          _c(
            "b-button",
            {
              staticClass: "beamerButton mr-3 dark-grey-buttons",
              attrs: {
                id: "tooltip-button-beamer",
                "data-beamer-click": "false"
              },
              on: { click: _vm.openBeamer }
            },
            [
              _c("b-icon", {
                staticClass: "top--1",
                attrs: {
                  icon: "bell-fill",
                  "aria-hidden": "true",
                  "font-scale": "1.1",
                  scale: "1.1"
                }
              })
            ],
            1
          ),
          _c(
            "b-tooltip",
            { attrs: { target: "tooltip-button-beamer", delay: "400" } },
            [_vm._v(" " + _vm._s(_vm.$t("tooltips.beamer")))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }