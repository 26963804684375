<template>
  <div v-if="isBeamerAvailable">
    <b-button id="tooltip-button-beamer"
              class="beamerButton mr-3 dark-grey-buttons"
              data-beamer-click="false"
              @click="openBeamer">
      <b-icon
        icon="bell-fill"
        aria-hidden="true"
        class="top--1"
        font-scale="1.1"
        scale="1.1"
      ></b-icon>
    </b-button>
    <b-tooltip target="tooltip-button-beamer" delay=400>
      {{ $t('tooltips.beamer') }}</b-tooltip>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';

export default {
  name: 'HeaderBeamerButton',
  data() {
    return {
      isBeamerAvailable: false,
    };
  },
  async mounted() {
    let user_email = null;
    try {
      // get the email of the user
      const currentSession = await Auth.currentSession();
      user_email = currentSession.getIdToken().payload.email;
    } catch (e) {
      console.error('there is no user email', e);
    }
    const beamerJs = document.createElement('script');
    beamerJs.setAttribute('src', '/beamer-embed.js');
    const product_id = (this.$i18n.locale === 'de-DE')
      ? 'taGjgdeU21573' // product_id for german
      : 'sYTfwxMu27309'; // product_id for english and default product_id
    beamerJs.addEventListener('load', () => {
      this.isBeamerAvailable = !!window.Beamer;
      window.beamer_config = {
        // DO NOT CHANGE: This is your product code on Beamer - en as default
        product_id,
        /* Optional: Id, class (or list of both) of the HTML element to use as a button */
        selector: '.beamerButton',
        /* Optional : true if you want to manually start the script by calling Beamer.init() */
        lazy: true,
      };
      /* ---------------Visitor Information--------------- */
      if (user_email
        && window.CCM
        && window.CCM.acceptedCookies
        && window.CCM.acceptedCookies.length > 0
        && window.CCM.acceptedCookies.includes('_BEAMER_USER_ID_#')
      ) {
        window.beamer_config.user_email = user_email;
        console.log('beamer config', window.beamer_config);
      }
      window.Beamer.init();
    });
    document.head.appendChild(beamerJs);
  },
  methods: {
    openBeamer() {
      if (window.Beamer) window.Beamer.show();
    },
  },
};
</script>

<style scoped>

</style>
